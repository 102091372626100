<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Caja </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <AreaSelect
                  :rules="reglas.requerido"
                  :label="'Area *'"
                  :clearable="true"
                  v-model="datos.idArea"
                  :dense="true"
                  :outlined="true"
                >
                </AreaSelect>
                <v-text-field
                  :rules="reglas.requerido"
                  v-model="datos.descripcion"
                  label="Descripcion *"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6 mt-5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  :rules="reglas.requerido"
                  v-model="datos.codigoCaja"
                  label="Numero Caja *"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-row class="mt-6">
                  <v-col cols="12" md="6">
                    <v-switch class="mt-0" v-model="datos.indAbrirGaveta" :label="`Abrir gaveta`"></v-switch>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch class="mt-0" v-model="datos.indFiscalizar" :label="`Fiscalizar`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-show="datos.indFiscalizar">
                <strong>Datos de la impresora</strong>
                <v-row class="mt-6">
                  <v-col cols="12" md="6"  >
                    <v-text-field
                      :rules="datos.indFiscalizar ? reglas.requerido : []"
                      v-model="datos.serialImpresora"
                      label="Serial Impresora *"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" >
                    <ModeloImpresoraFiscalSelect
                      :rules="datos.indFiscalizar ? reglas.requerido : []"
                      :clearable="true"
                      v-model="datos.idModeloImpresoraFiscal"
                      
                      :dense="true"
                      :outlined="true"
                    />

                  
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.bAudiosImpresora"
                      label="bAudiosImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.paridadImpresora"
                      label="paridadImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.puertoImpresora"
                      label="puertoImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.stopBitsImpresora"
                      label="stopBitsImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.handshakeImpresora"
                      label="handshakeImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field
                      v-model="datos.maxTiempoEsperaImpresora"
                      label="maxTiempoEsperaImpresora"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import CajaServices from '@/api/servicios/CajaServices'
import AreaSelect from '@/views/sistema/inventario/archivo/area/componentes/AreaSelect.vue'
import ModeloImpresoraFiscalSelect from '@/views/sistema/configuracion/modelo-impresora-fiscal/componentes/Select.vue'
export default {
  components: {
    AreaSelect,
    ModeloImpresoraFiscalSelect,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }

    const datosInit = {
      id: -1 /*int*/,
      idArea: null /*int*/,
      codigoCaja: '' /*int*/,
      descripcion: '' /*string*/,
      serialImpresora: '' /*string*/,
      puertoBalanza: '' /*string*/,
      idModeloImpresoraFiscal: null /*int*/,
      bAudiosImpresora: '' /*string*/,
      paridadImpresora: '' /*string*/,
      puertoImpresora: '' /*string*/,
      stopBitsImpresora: '' /*string*/,
      handshakeImpresora: '' /*string*/,
      maxTiempoEsperaImpresora: '' /*string*/,
      tiempoAntesLecturaImpresora: '' /*string*/,
      indAbrirGaveta: false /*bool*/,
      indActivo: true /*bool*/,
      indFiscalizar: false /*bool*/,
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      form.value.resetValidation()
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = item
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          CajaServices.Actualizar(
            store.state.user.idUsuario,
            datos.value, 
          )
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
    }
  },
}
</script>