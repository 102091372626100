//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ModeloImpresoraFiscalServices {
        
    

    Consultar(datos) {
        return http.get("ModeloImpresoraFiscal/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 
}


export default new ModeloImpresoraFiscalServices();